import React, { useContext } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layouts/layout";
import SinglePost from "../components/SinglePost/singlePost";

import { ThemeContext, ThemeProvider } from "../Context/ThemeContext";



const Post = ({ data, location }) => {
    const post = data.ghostPost;

    const theme = useContext(ThemeContext);
    const darkMode = theme?.state?.darkMode;



    return (
        <>
            {/* <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet> */}
            <ThemeProvider>

                <Layout>
                    <SinglePost post={post} />
                </Layout>
            </ThemeProvider>

        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired
};

export default Post;

export const postQuery = graphql`
    query ($id: String!) {
        ghostPost(id: { eq: $id }) {
            ...GhostPostFields
        }
    }
`;
