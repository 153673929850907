import React from 'react'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useContext } from "react";
import sanitize from "sanitize-html";
import HeartHand from "../../../static/images/single_link.svg";
import WhiteHand from "../../../static/images/single_link_white.svg";
import { ThemeContext } from "../../Context/ThemeContext";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "../../styles/app.css";
import { generateTableOfContent } from "../../utils/table-of-content";
import { generateSmartDeliveryURLS } from "../../utils/cloudinary";
import BlogSlider from "../BlogSlider/blogSlider";
import SEO from '../Seo/seo';

const SinglePost = ({ post }) => {
  console.log("POST HTML", post.html);
  console.log("SANITIZED HTML", sanitize(post.html));
  const theme = useContext(ThemeContext);
  const darkMode = theme?.state?.darkMode;
  const { allH2 } = generateTableOfContent(post.html);
  const onClickLink = (id) => {
    console.log("ID", id);
    if (window && window.document) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const title = post?.meta_title || post.title
  const metaDescription = post?.meta_description

  return (
    <div>
      <SEO title={title} metaDescription={metaDescription}
        canonical={url}
        metaTitle={post?.meta_title} />
      <div
        className={` ${darkMode ? "blog_container_dark single_post" : "blog_container"
          }`}
      >
        <div className="global">
          <article className="content mt-5 pt-5">
            {post.feature_image ? (
              <img
                src={generateSmartDeliveryURLS(post.feature_image)}
                className="post-feature-image pt-0"
                alt=""
              />
            ) : // <figure className="post-feature-image pt-5" style={{ backgroundImage: `url(${post.feature_image})` }}>
              // </figure>
              null}
            <div className="row">
              <div className="col-md-8 order-lg-first order-last">
                <LazyLoadComponent>
                  <section
                    className="post-full-content"
                    style={{ background: "transparent" }}
                  >
                    <h1
                      className={`${darkMode
                        ? "content-title content-title-dark"
                        : "content-title"
                        }`}
                    >
                      {post.title}
                    </h1>

                    {/* The main post content */}
                    <section
                      className={`mt-3 ${darkMode
                        ? "content-body load-external-scripts content_body_dark"
                        : "content-body load-external-scripts"
                        }`}
                      dangerouslySetInnerHTML={{
                        __html: post.html,
                      }}
                    />
                  </section>
                </LazyLoadComponent>
              </div>
              <div className="col-lg-4" style={{ position: "relative" }}>
                <div
                  className="singlePost-article"
                  style={{
                    position: "sticky",
                    top: "80px",
                    paddingRight: "0px",
                  }}
                >
                  {/* <div className="singlePost-article-title">
                    <p>Содержание</p>
                  </div> */}
                  <div
                    className={`mt-3 ${darkMode ? "content_dark" : ""
                      } aboutBlog-article-links `}
                  >
                    <ul>
                      {allH2
                        ? allH2.map((h2) => {
                          return (
                            <li
                              key={h2.title}
                              style={{
                                listStyleType: "none",
                                cursor: "pointer",
                              }}
                            >
                              <a onClick={() => onClickLink(h2.id)}>
                                {h2.title}
                              </a>
                            </li>
                          );
                        })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <LazyLoadComponent>
          <BlogSlider />
        </LazyLoadComponent>
        <div className="mb-5 text-center">
          <div className="load-more-img">
            <img src={darkMode ? WhiteHand : HeartHand} alt="" />

          </div>

          <Link to="/#all_blogs" className="button_Load mt-3">
            все статьи
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
